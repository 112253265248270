(function (window) {
  // per app-config... for avoiding the modifications of the original unitConverter.js file
  var conversions = window.unitConverter.conversions;

  conversions["CurrencyPerVolume"]["USD$/bbl"].enabled = false;
  conversions["CurrencyPerVolume"]["USD$/m3"].enabled = false;

  conversions["CurrencyPerVolume"]["CAD$/bbl"].defaultUnit = true;
  conversions["CurrencyPerVolume"]["CAD$/m3"].defaultUnit = false;

  conversions["Length"]["m"].defaultUnit = false;
  conversions["Length"]["km"].defaultUnit = true;

  conversions["Dimensionless"]["ppm"].enabled = false;

  conversions["Volume"]["BCF"].enabled = true;

  conversions['MassFraction']['ppmw'].defaultUnit = false;
  conversions['MassFraction']['wt%'].defaultUnit = true;

  conversions["EnergyPerVolume"]["GJ/bbl"].enabled = false;
  conversions["EnergyPerVolume"]["MJ/bbl"].enabled = false;
  conversions["EnergyPerVolume"]["Btu/bbl"].enabled = false;

  conversions["EnergyPerVolume"]["BTU/scf"].defaultUnit = true;


  conversions["Molarflowrate"]["MMSCFD"].defaultUnit = true;
  conversions["Molarflowrate"]["mol/s"].enabled = false;
  conversions["Molarflowrate"]["kmol/s"].enabled = false;
  conversions["Molarflowrate"]["lbmol/s"].enabled = false;
  conversions["Molarflowrate"]["Sm3/s"].enabled = false;

  conversions['MoleFraction']['mole fraction'].defaultUnit = false;
  conversions['MoleFraction']['mole%'].defaultUnit = true;


})(window);
