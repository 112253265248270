(function (win, ns) {
  'use strict';

  var DEFAULT_PRECISION = 2;

  var conversions;
  var units = {
    Angle: {
      RAD: 'rad',
      DEG: 'deg',
    },

    Currency: {
      CAD: 'CAD$',
      MMCAD: 'MM CAD$',
      USD: 'USD$',
    },

    CurrencyPerElectricity: {
      CAD_J: 'CAD$/J',
      CAD_KWH: 'CAD$/kWh',
      CAD_MEGAWH: 'CAD$/MWh',
    },

    CurrencyPerEnergy: {
      CAD_J: 'CAD$/J',
      CAD_GJ: 'CAD$/GJ',
    },

    CurrencyPerMass: {
      CAD_KG: 'CAD$/kg',
      CAD_TONNES: 'CAD$/tonnes',
    },

    CurrencyPerMole: {
      CAD_GJ: 'CAD$/GJ',
    },

    CurrencyPerTime: {
      CAD_S: 'CAD$/s',
      CAD_HR: 'CAD$/hr',
      CAD_D: 'CAD$/d',
      CAD_YR: 'CAD$/yr',
      MMCAD_YR: 'MM CAD$/yr',
    },

    CurrencyPerVolume: {
      CAD_M3: 'CAD$/m3',
      CAD_BBL: 'CAD$/bbl',
      USD_M3: 'USD$/m3',
      USD_BBL: 'USD$/bbl',
    },

    Day: {
      DAY: 'day',
    },

    DeltaPressurePerLength: {
      PA_M: 'Pa/m',
      KPA_KM: 'kPa/km',
    },

    DeltaTemperature: {
      CELSIUS: 'C',
      KELVIN: 'K',
      FAHRENHEIT: 'F',
    },

    DeltaPressure: {
      PA: 'Pa',
      KPAA: 'kPaa',
      MPAA: 'MPaa',
      PSIA: 'Psia',
    },

    Dimensionless: {
      FRACTION: 'fraction',
      PPM: 'ppm',
      PERCENT: '%',
    },

    EnergyPerMass: {
      J_KG: 'J/kg',
      GJ_TONNES: 'GJ/tonnes',
    },

    EnergyPerMole: {
      J_MOL: 'J/mol',
      KJ_MOL: 'kJ/mol',
    },

    EnergyPerPower: {
      DIMENSIONLESS: ' ',
      KJ_KWH: 'kJ/kwh',
    },

    EnergyPerVolume: {
      J_M3: 'J/m3',
      MJ_M3: 'MJ/m3',
      GJ_M3: 'GJ/m3',
      BTU_SCF: 'BTU/scf',
      GJ_BBL: 'GJ/bbl',
      MJ_BBL: 'MJ/bbl',
      BTU_BBL: 'Btu/bbl',
    },

    Energyrate: {
      J_S: 'J/s',
      GJ_H: 'GJ/h',
      GJ_D: 'GJ/d',
      BTU_D: 'Btu/d',
      BBTU_D: 'BBtu/d',
    },

    Fraction: {
      FRACTION: 'fraction',
    },

    Length: {
      M: 'm',
      KM: 'km',
      CM: 'cm',
    },

    LiquidDensity: {
      KG_M3: 'kg/m3',
      API: 'API',
      SG: 'SG(60 F)',
    },

    Massflowrate: {
      KG_S: 'kg/s',
      KG_HR: 'kg/h',
      KPPH: 'kpph',
      KG_D: 'kg/d',
      MMTPA: 'MMtpa',
      TONNES_D: 'tonnes/d',
      TONNES_YR: 'tonnes/yr',
    },

    MassFraction: {
      FRACTION: 'fraction',
      PPMW: 'ppmw',
      WATER_PERCENT: 'wt%',
    },

    MassGHGPerMassDistance: {
      KGCO2E_KGKM: 'kgCO2e/kg.km ',
      KGCO2E_TONNEKM: 'kgCO2e/tonne.km',
    },

    MassPerEnergy: {
      KG_J: 'kgCO2e/J',
      KG_GJ: 'kgCO2e/GJ',
      KG_KWH: 'kgCO2e/kWh',
    },

    MassEmissionsPerVolume: {
      KG_M3: 'kgCO2e/m3',
      KG_SCF: 'kgCO2e/scf',
    },

    MassEmissionsPerFuelMass: {
      KGCO2E_KG: 'kgCO2e/kg',
      KGCO2E_TONNE: 'kgCO2e/tonne',
    },

    MassPerVolume: {
      KG_M3: 'kg/m3',
      KG_BBL: 'kg/bbl',
    },

    MassPerPower: {
      KG_W: 'kg/W',
    },

    Molarflowrate: {
      MOL_S: 'mol/s',
      KMOL_S: 'kmol/s',
      KMOL_H: 'kmol/h',
      LBMOL_S: 'lbmol/s',
      LBMOL_H: 'lbmol/h',
      SM3_S: 'Sm3/s',
      MSCFD: 'MSCFD',
      MMSCFD: 'MMSCFD',
    },

    MoleFraction: {
      FRACTION: 'mole fraction',
      PPM: 'ppm',
      PERCENT: 'mole%',
    },

    Moleflowrate: {
      M3_S: 'm3/s',
      MSCFD: 'MSCFD',
      MMSCFD: 'MMSCFD',
      E3M3_D: 'E3M3/d',
      KGMOLE_H: 'kgmole/h',
    },

    Percent: {
      PERCENT: '%',
      FRACTION: 'fraction',
    },

    Power: {
      WATTS: 'Watts',
      KW: 'kW',
      MEGAW: 'MW',
      GJ_H: 'GJ/h',
    },

    PowerPerMolarFlow: {
      W_M3S: 'W/(m3/s)',
      KW_E3M3D: 'kW/(E3M3/d)',
      KW_MMSCFD: 'kW/MMSCFD',
    },

    PowerPerVolumetricFlow: {
      W_M3S: 'W/(m3/s)',
      KW_M3D: 'kW/(m3/d)',
      KW_BBLD: 'kW/(bbl/d)',
      KWH_BBL: 'kWh/bbl',
    },

    Pressure: {
      PA: 'Pa',
      KPAA: 'kPaa',
      MPAA: 'MPaa',
      PSIA: 'Psia',
    },

    Speed: {
      M_S: 'm/s',
      KM_H: 'km/h',
    },

    Temperature: {
      CELSIUS: 'C',
      FAHRENHEIT: 'F',
      KELVIN: 'K',
    },

    U: {
      W_M2K: 'W/m2K',
      KW_M2K: 'kW/m2K',
      BTU_HFT2R: 'BTU/hft2R',
    },

    UA: {
      W_K: 'W/K',
    },

    Volume: {
      M3: 'm3',
      BBL: 'bbl',
      BCF: 'BCF',
    },

    VolumeFraction: {
      VOLUME_PERCENT: 'Vol%',
      PPMV: 'ppmv',
      FRACTION: 'fraction'
    },

    Volumetricflowrate: {
      M3_S: 'm3/s',
      BPCD: 'bpcd',
      KBPCD: 'kbpcd',
      M3_D: 'm3/d',
      USGPM: 'USGPM',
    },

    VolumePerDistance: {
      M3_M: 'm3/m',
      L_100KM: 'L/100 km',
    },

    GasStdVolumePerVolume: {
      SCF_BBL: 'scf/bbl',
      M3_M3: 'm3/m3',
    },

    CurrencyPerStdH2Volume: {
      CAD_M3: 'CAD$/m3',
      CAD_MMSCF: 'CAD$/MMSCF',
      CAD_tonne: 'CAD$/tonne',
    },
    AmineUseRate: {
      USGPM_MMSCFD: 'USGPM/MMSCFD',
    },

    MassPerStdH2Volume: {
      KGCO2E_M3: 'kgCO2e/m3',
      KGCO2E_MMSCF: 'kgCO2e/MMSCF',
      KGCO2E_tonne: 'kgCO2e/tonne',
    },
  };

  conversions = {
    Angle: {
      rad: {
        key: units.Angle.RAD,
        internalUnit: null,
        enabled: true,
        precision: 5,
      },
      deg: {
        key: units.Angle.DEG,
        internalUnit: units.Angle.RAD,
        enabled: true,
        defaultUnit: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * (180 / Math.PI);
          return value * (Math.PI / 180);
        },
        precision: 5,
      },
    },

    Currency: {
      CAD$: {
        key: units.Currency.CAD,
        internalUnit: null,
        enabled: true,
      },

      'MM CAD$': {
        key: units.Currency.MMCAD,
        enabled: true,
        defaultUnit: true,
        internalUnit: units.Currency.CAD,
        factor: 1e6,
      },

      USD$: {
        key: units.Currency.USD,
        internalUnit: units.Currency.CAD,
        factor: 1 / 0.75, //leave this as value, if changed, all conversions from/to USD$ must be adjusted
      },
    },

    CurrencyPerEnergy: {
      'CAD$/J': {
        key: units.CurrencyPerEnergy.CAD_J,
        internalUnit: null,
      },

      'CAD$/GJ': {
        key: units.CurrencyPerEnergy.CAD_GJ,
        internalUnit: units.CurrencyPerEnergy.CAD_J,
        defaultUnit: true,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 1e9;
          return value / 1e9;
        },
      },
    },

    CurrencyPerElectricity: {
      'CAD$/J': {
        key: units.CurrencyPerElectricity.CAD_J,
        internalUnit: null,
        precision: 10,
      },

      'CAD$/kWh': {
        key: units.CurrencyPerElectricity.CAD_KWH,
        internalUnit: units.CurrencyPerElectricity.CAD_J,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 3600000;
          return value / 3600000;
        },
      },

      'CAD$/MWh': {
        key: units.CurrencyPerElectricity.CAD_MEGAWH,
        internalUnit: units.CurrencyPerElectricity.CAD_J,
        defaultUnit: true,
        precision: 9,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 3600000000;
          return value / 3600000000;
        },
      },
    },

    CurrencyPerMass: {
      'CAD$/kg': {
        key: units.CurrencyPerMass.CAD_KG,
        internalUnit: null,
        enabled: true,
      },

      'CAD$/tonnes': {
        key: units.CurrencyPerMass.CAD_TONNES,
        internalUnit: units.CurrencyPerMass.CAD_KG,
        enabled: true,
        defaultUnit: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 1000;
          return value / 1000;
        },
      },
    },

    CurrencyPerMole: {
      'CAD$/GJ': {
        key: units.CurrencyPerMole,
        internalUnit: null,
        enabled: true,
      },
    },

    CurrencyPerTime: {
      'CAD$/s': {
        key: units.CurrencyPerTime.CAD_S,
        internalUnit: null,
      },

      'CAD$/hr': {
        key: units.CurrencyPerTime.CAD_HR,
        internalUnit: units.CurrencyPerTime.CAD_S,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) {
            return value * 3600;
          }

          return value / 3600;
        },
      },

      'CAD$/d': {
        key: units.CurrencyPerTime.CAD_D,
        internalUnit: units.CurrencyPerTime.CAD_S,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 86400.0;
          return value / 86400.0;
        },
      },

      'CAD$/yr': {
        key: units.CurrencyPerTime.CAD_YR,
        internalUnit: units.CurrencyPerTime.CAD_S,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 31536000;
          return value / 31536000;
        },
      },

      'MM CAD$/yr': {
        key: units.CurrencyPerTime.MMCAD_YR,
        internalUnit: units.CurrencyPerTime.CAD_S,
        enabled: true,
        defaultUnit: true,
        factor: function (value, toCapture) {
          if (toCapture) return (value * 31536000) / 1e6;
          return (value / 31536000) * 1e6;
        },
      },
    },

    CurrencyPerVolume: {
      'CAD$/bbl': {
        key: units.CurrencyPerVolume.CAD_BBL,
        internalUnit: units.CurrencyPerVolume.CAD_M3,
        defaultUnit: true,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 0.1589873;
          return value / 0.1589873;
        },
      },

      'USD$/bbl': {
        key: units.CurrencyPerVolume.USD_BBL,
        internalUnit: units.CurrencyPerVolume.CAD_M3,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return (value * 0.1589873) / conversions.Currency[units.Currency.USD].factor;
          return (value / 0.1589873) * conversions.Currency[units.Currency.USD].factor;
        },
      },

      'CAD$/m3': {
        key: units.CurrencyPerVolume.CAD_M3,
        internalUnit: null,
        enabled: true,
      },

      'USD$/m3': {
        key: units.CurrencyPerVolume.USD_M3,
        internalUnit: units.CurrencyPerVolume.CAD_M3,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value / conversions.Currency[units.Currency.USD].factor;
          return value * conversions.Currency[units.Currency.USD].factor;
        },
      },
    },

    Day: {
      day: {
        key: units.Day.DAY,
        internalUnit: null,
        enabled: true,
        defaultUnit: true,
      },
    },

    DeltaPressure: {
      Pa: {
        key: units.DeltaPressure.PA,
        internalUnit: null,
        enabled: false,
      },

      kPaa: {
        key: units.DeltaPressure.KPAA,
        internalUnit: units.DeltaPressure.PA,
        defaultUnit: true,
        enabled: true,
        factor: 1e3,
      },

      MPaa: {
        key: units.DeltaPressure.MPAA,
        internalUnit: units.DeltaPressure.PA,
        enabled: true,
        factor: 1e6,
      },

      Psia: {
        key: units.DeltaPressure.PSIA,
        internalUnit: units.DeltaPressure.PA,
        enabled: true,
        factor: 6.89476 * 1e3,
      },
    },

    DeltaPressurePerLength: {
      'Pa/m': {
        key: units.DeltaPressurePerLength.PA_M,
        internalUnit: null,
        enabled: false,
      },

      'kPa/km': {
        key: units.DeltaPressurePerLength.KPA_KM,
        internalUnit: units.DeltaPressurePerLength.PA_M,
        enabled: true,
        defaultUnit: true,
        factor: 1,
      },
    },

    DeltaTemperature: {
      K: {
        key: units.DeltaTemperature.KELVIN,
        internalUnit: null,
        enabled: true,
      },

      C: {
        key: units.DeltaTemperature.CELSIUS,
        internalUnit: units.DeltaTemperature.KELVIN,
        defaultUnit: true,
        enabled: true,
        factor: 1,
      },

      F: {
        key: units.DeltaTemperature.FAHRENHEIT,
        internalUnit: units.DeltaTemperature.KELVIN,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) {
            return value * 1.8;
          }

          return value / 1.8;
        },
      },
    },

    Dimensionless: {
      fraction: {
        key: units.Dimensionless.FRACTION,
        internalUnit: null,
        defaultUnit: true,
        enabled: true,
        precision: 5,
      },

      ppm: {
        key: units.Dimensionless.PPM,
        internalUnit: units.Dimensionless.FRACTION,
        enabled: true,
        precision: 1,
        factor: function (value, toCapture) {
          if (toCapture) return value * 1e6;
          return value / 1e6;
        },
      },

      '%': {
        key: units.Dimensionless.PERCENT,
        internalUnit: units.Dimensionless.FRACTION,
        precision: 5,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 1e2;
          return value / 1e2;
        },
      },
    },

    EnergyPerMass: {
      'J/kg': {
        key: units.EnergyPerMass.J_KG,
        internalUnit: null,
      },

      'GJ/tonnes': {
        key: units.EnergyPerMass.GJ_TONNES,
        internalUnit: units.EnergyPerMass.J_KG,
        enabled: true,
        defaultUnit: true,
        factor: 1e6,
      },
    },

    EnergyPerMole: {
      'J/mol': {
        key: units.EnergyPerMole.J_MOL,
        internalUnit: undefined,
        defaultUnit: true,
        enabled: true,
      },

      'kJ/mol': {
        key: units.EnergyPerMole.KJ_MOL,
        internalUnit: units.EnergyPerMole.J_MOL,
        enabled: true,
        factor: 1e3,
      },
    },

    MassEmissionsPerFuelMass: {
      'kgCO2e/kg': {
        key: units.MassEmissionsPerFuelMass.KGCO2E_KG,
        internalUnit: null,
        defaultUnit: false,
        enabled: true,
      },

      'kgCO2e/tonne': {
        key: units.MassEmissionsPerFuelMass.KGCO2E_TONNE,
        internalUnit: units.MassEmissionsPerFuelMass.KGCO2E_KG,
        defaultUnit: true,
        enabled: true,
        precision: 2,
        factor: 0.001,
      },
    },

    EnergyPerPower: {
      ' ': {
        key: units.EnergyPerPower.DIMENSIONLESS,
        internalUnit: null,
      },

      'kJ/kwh': {
        key: units.EnergyPerPower.KJ_KWH,
        internalUnit: units.EnergyPerPower.DIMENSIONLESS,
        defaultUnit: true,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 3600;
          return value / 3600;
        },
      },
    },

    EnergyPerVolume: {
      'J/m3': {
        key: units.EnergyPerVolume.J_M3,
        internalUnit: null,
      },

      'MJ/m3': {
        key: units.EnergyPerVolume.MJ_M3,
        internalUnit: units.EnergyPerVolume.J_M3,
        defaultUnit: true,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 1e-6;
          return value / 1e-6;
        },
      },

      'GJ/m3': {
        key: units.EnergyPerVolume.GJ_M3,
        internalUnit: units.EnergyPerVolume.J_M3,
        precision: 5,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 1e-9;
          return value / 1e-9;
        },
      },
      'BTU/scf': {
        key: units.EnergyPerVolume.BTU_SCF,
        internalUnit: units.EnergyPerVolume.J_M3,
        precision: 2,
        enabled: true,
        factor: 37258.94581,
      },
      'GJ/bbl': {
        key: units.EnergyPerVolume.GJ_BBL,
        internalUnit: units.EnergyPerVolume.J_M3,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) {
            return value * 0.1589873e-9;
          }
          return value / 0.1589873e-9;
        },
      },

      'MJ/bbl': {
        key: units.EnergyPerVolume.MJ_BBL,
        internalUnit: units.EnergyPerVolume.J_M3,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) {
            return value * 0.1589873e-6;
          }

          return value / 0.1589873e-6;
        },
      },

      'Btu/bbl': {
        key: units.EnergyPerVolume.BTU_BBL,
        internalUnit: units.EnergyPerVolume.J_M3,
        enabled: true,
        factor: 6636.12754,
      },
    },

    Energyrate: {
      'J/s': {
        key: units.Energyrate.J_S,
        internalUnit: null,
      },

      'GJ/h': {
        key: units.Energyrate.GJ_H,
        internalUnit: units.Energyrate.J_S,
        defaultUnit: false,
        enabled: true,
        factor: 277777.7778,
      },

      'GJ/d': {
        key: units.Energyrate.GJ_D,
        internalUnit: units.Energyrate.J_S,
        enabled: true,
        factor: 11574.07407,
      },
      'Btu/d': {
        key: units.Energyrate.BTU_D,
        internalUnit: units.Energyrate.J_S,
        defaultUnit: false,
        enabled: false,
        factor: 1055.06 / (24.0 * 3600.0),
      },
      'BBtu/d': {
        key: units.Energyrate.BBTU_D,
        internalUnit: units.Energyrate.J_S,
        defaultUnit: true,
        enabled: true,
        factor: (1e9 * 1055.06) / (24.0 * 3600.0),
      },
    },

    Fraction: {
      fraction: {
        key: units.Fraction.FRACTION,
        internalUnit: null,
        enabled: true,
        defaultUnit: true,
      },
    },

    MassEmissionsPerVolume: {
      'kgCO2e/m3': {
        key: units.MassEmissionsPerVolume.KG_M3,
        internalUnit: null,
        enabled: true,
        defaultUnit: false,
        precision: 4,
      },

      'kgCO2e/scf': {
        key: units.MassEmissionsPerVolume.KG_SCF,
        internalUnit: units.MassEmissionsPerVolume.KG_M3,
        enabled: true,
        factor: 35.31466671,
        defaultUnit: true,
        precision: 4,
      },
    },

    Length: {
      m: {
        key: units.Length.M,
        internalUnit: null,
        enabled: true,
        defaultUnit: true,
        precision: 5,
      },

      km: {
        key: units.Length.KM,
        internalUnit: units.Length.M,
        enabled: true,
        factor: 1000,
        precision: 5,
      },

      cm: {
        key: units.Length.CM,
        internalUnit: units.Length.M,
        enabled: true,
        factor: 0.01,
        precision: 5,
      },
    },

    LiquidDensity: {
      API: {
        key: units.LiquidDensity.API,
        internalUnit: units.LiquidDensity.KG_M3,
        defaultUnit: true,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return (141.5 * 999.01557) / value - 131.5;
          return (141.5 * 999.01557) / (value + 131.5);
        },
      },

      'SG(60 F)': {
        key: units.LiquidDensity.SG,
        internalUnit: units.LiquidDensity.KG_M3,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value / 999.01557;
          return value * 999.01557;
        },
      },

      'kg/m3': {
        key: units.LiquidDensity.KG_M3,
        enabled: true,
        internalUnit: null,
      },
    },

    Massflowrate: {
      'kg/s': {
        key: units.Massflowrate.KG_S,
        internalUnit: null,
      },

      'kg/h': {
        key: units.Massflowrate.KG_HR,
        internalUnit: units.Massflowrate.KG_S,
        defaultUnit: false,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 3600.0;
          return value / 3600.0;
        },
      },
      kpph: {
        key: units.Massflowrate.KPPH,
        internalUnit: units.Massflowrate.KG_S,
        defaultUnit: true,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 3600.0 * 0.001 * 2.20462;
          return value / (3600.0 * 0.001 * 2.20462);
        },
      },
      MMtpa: {
        key: units.Massflowrate.MMTPA,
        internalUnit: units.Massflowrate.KG_S,
        defaultUnit: false,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 3600.0 * 24 * 365 * 1e-6 * 1e-3;
          return (value * 1e6 * 1e3) / 3600.0 / 24 / 365;
        },
      },
      'kg/d': {
        key: units.Massflowrate.KG_D,
        internalUnit: units.Massflowrate.KG_S,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 86400.0;
          return value / 86400.0;
        },
      },

      'tonnes/d': {
        key: units.Massflowrate.TONNES_D,
        internalUnit: units.Massflowrate.KG_S,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 86.4;
          return value / 86.4;
        },
      },

      'tonnes/yr': {
        key: units.Massflowrate.TONNES_YR,
        internalUnit: units.Massflowrate.KG_S,
        enabled: false,
        factor: function (value, toCapture) {
          if (toCapture) return value * 31536.0;
          return value / 31536.0;
        },
      },
    },

    MassFraction: {
      ppmw: {
        key: units.MassFraction.PPMW,
        internalUnit: units.MassFraction.FRACTION,
        defaultUnit: true,
        enabled: true,
        precision: 1,
        factor: function (value, toCapture) {
          if (toCapture) return value * 1e6;
          return value / 1e6;
        },
      },

      'wt%': {
        key: units.MassFraction.WATER_PERCENT,
        internalUnit: units.MassFraction.FRACTION,
        enabled: true,
        precision: 5,
        factor: function (value, toCapture) {
          if (toCapture) return value * 1e2;
          return value / 1e2;
        },
      },

      fraction: {
        key: units.MassFraction.FRACTION,
        internalUnit: null,
        enabled: true,
        precision: 7,
      },
    },

    MassGHGPerMassDistance: {
      'kgCO2e/kg.km ': {
        key: units.MassGHGPerMassDistance.KGCO2E_KGKM,
        internalUnit: null,
        enabled: true,
        defaultUnit: true,
        precision: 3,
      },
      'kgCO2e/tonne.km': {
        key: units.MassGHGPerMassDistance.KGCO2E_TONNEKM,
        internalUnit: units.MassGHGPerMassDistance.KGCO2E_KGKM,
        enabled: false,
        factor: 0.001,
      },
    },

    MassPerVolume: {
      'kg/m3': {
        key: units.MassPerVolume.KG_M3,
        internalUnit: null,
        enabled: true,
        defaultUnit: false,
      },

      'kg/bbl': {
        key: units.MassPerVolume.KG_BBL,
        enabled: true,
        defaultUnit: true,
        internalUnit: units.MassPerVolume.KG_M3,
        factor: function (value, toCapture) {
          if (toCapture) return value * 0.1589873;
          return value / 0.1589873;
        },
      },
    },

    MassPerEnergy: {
      'kgCO2e/J': {
        key: units.MassPerEnergy.KG_J,
        internalUnit: null,
        enabled: true,
      },
      'kgCO2e/GJ': {
        key: units.MassPerEnergy.KG_GJ,
        internalUnit: units.MassPerEnergy.KG_J,
        defaultUnit: true,
        enabled: true,
        factor: 1 / 1e9,
      },
      'kgCO2e/kWh': {
        key: units.MassPerEnergy.KG_KWH,
        internalUnit: units.MassPerEnergy.KG_J,
        defaultUnit: true,
        enabled: true,
        factor: 1 / 3.6e6,
      },
    },

    MassPerPower: {
      'kg/W': {
        key: units.MassPerPower.KG_W,
        internalUnit: null,
        defaultUnit: true,
        enabled: true,
      },
    },

    Molarflowrate: {
      'mol/s': {
        key: units.Molarflowrate.MOL_S,
        internalUnit: null,
        enabled: true,
        defaultUnit: false,
      },
      'kmol/s': {
        key: units.Molarflowrate.KMOL_S,
        internalUnit: units.Molarflowrate.MOL_S,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 0.001;
          return value / 0.001;
        },
      },
      'kmol/h': {
        key: units.Molarflowrate.KMOL_H,
        internalUnit: units.Molarflowrate.MOL_S,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 3.6;
          return value / 3.6;
        },
      },
      'lbmol/s': {
        key: units.Molarflowrate.LBMOL_S,
        internalUnit: units.Molarflowrate.MOL_S,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 0.00220462262184878;
          return value / 0.00220462262184878;
        },
      },
      'lbmol/h': {
        key: units.Molarflowrate.LBMOL_H,
        internalUnit: units.Molarflowrate.MOL_S,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 7.936641438655;
          return value / 7.936641438655;
        },
      },
      'Sm3/s': {
        key: units.Molarflowrate.SM3_S,
        internalUnit: units.Molarflowrate.MOL_S,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value / 42.2111122877301057374013;
          return value * 42.2111122877301057374013;
        },
      },
      MSCFD: {
        key: units.Molarflowrate.MSCFD,
        internalUnit: units.Molarflowrate.MOL_S,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value / 0.0138353006190013;
          return value * 0.0138353006190013;
        },
      },
      MMSCFD: {
        key: units.Molarflowrate.MMSCFD,
        internalUnit: units.Molarflowrate.MOL_S,
        enabled: true,
        defaultUnit: true,
        factor: function (value, toCapture) {
          if (toCapture) return value / 13.8353006190013;
          return value * 13.8353006190013;
        },
      },
    },

    Moleflowrate: {
      'm3/s': {
        key: units.Moleflowrate.M3_S,
        internalUnit: null,
      },

      MMSCFD: {
        key: units.Moleflowrate.MMSCFD,
        internalUnit: units.Moleflowrate.M3_S,
        defaultUnit: true,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 3.058176;
          return value / 3.058176;
        },
      },

      MSCFD: {
        key: units.Moleflowrate.MSCFD,
        internalUnit: units.Moleflowrate.M3_S,
        defaultUnit: true,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 3051.19008;
          return value / 3051.19008;
        },
      },

      'E3M3/d': {
        key: units.Moleflowrate.E3M3_D,
        internalUnit: units.Moleflowrate.M3_S,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 86.4;
          return value / 86.4;
        },
      },

      'kgmole/h': {
        key: units.Moleflowrate.KGMOLE_H,
        internalUnit: units.Moleflowrate.M3_S,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 151.9601851;
          return value / 151.9601851;
        },
      },
    },

    MoleFraction: {
      'mole fraction': {
        key: units.MoleFraction.FRACTION,
        internalUnit: null,
        defaultUnit: true,
        enabled: true,
        precision: 4,
      },

      ppm: {
        key: units.MoleFraction.PPM,
        internalUnit: units.MoleFraction.FRACTION,
        enabled: true,
        precision: 2,
        factor: function (value, toCapture) {
          if (toCapture) return value * 1e6;
          return value / 1e6;
        },
      },

      'mole%': {
        key: units.MoleFraction.PERCENT,
        internalUnit: units.MoleFraction.FRACTION,
        precision: 3,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 1e2;
          return value / 1e2;
        },
      },
    },

    Percent: {
      '%': {
        key: units.Percent.PERCENT,
        internalUnit: null,
        defaultUnit: true,
        enabled: true,
      },

      fraction: {
        key: units.Percent.FRACTION,
        internalUnit: units.Percent.PERCENT,
        enabled: true,
        factor: 100.0,
      },
    },

    Power: {
      Watts: {
        key: units.Power.WATTS,
        internalUnit: null,
      },

      kW: {
        key: units.Power.KW,
        internalUnit: units.Power.WATTS,
        enabled: true,
        factor: 1e3,
      },

      MW: {
        key: units.Power.MEGAW,
        internalUnit: units.Power.WATTS,
        defaultUnit: true, //this might change
        enabled: true,
        factor: 1e6,
      },

      'GJ/h': {
        key: units.Power.GJ_H,
        internalUnit: units.Power.WATTS,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 3.6e-6;
          return value / 3.6e-6;
        },
      },
    },

    PowerPerMolarFlow: {
      'W/(m3/s)': {
        key: units.PowerPerMolarFlow.W_M3S,
        internalUnit: null,
      },

      'kW/(E3M3/d)': {
        key: units.PowerPerMolarFlow.KW_E3M3D,
        internalUnit: units.PowerPerMolarFlow.W_M3S,
        enabled: true,
        defaultUnit: true,
        factor: 86400,
      },

      'kW/MMSCFD': {
        key: units.PowerPerMolarFlow.KW_MMSCFD,
        internalUnit: units.PowerPerMolarFlow.W_M3S,
        enabled: true,
        factor: 3058.176,
      },
    },

    PowerPerVolumetricFlow: {
      'W/(m3/s)': {
        key: units.PowerPerVolumetricFlow.W_M3S,
        internalUnit: null,
      },

      'kW/(m3/d)': {
        key: units.PowerPerVolumetricFlow.KW_M3D,
        internalUnit: units.PowerPerVolumetricFlow.W_M3S,
        enabled: false,
        defaultUnit: false,
        precision: 3,
        factor: function (value, toCapture) {
          if (toCapture) return value * 1.15741e-8;
          return value / 1.15741e-8;
        },
      },

      'kW/(bbl/d)': {
        key: units.PowerPerVolumetricFlow.KW_BBLD,
        internalUnit: units.PowerPerVolumetricFlow.W_M3S,
        enabled: false,
        precision: 3,
        factor: function (value, toCapture) {
          if (toCapture) return value * 7.27987e-8;
          return value / 7.27987e-8;
        },
      },

      'kWh/bbl': {
        key: units.PowerPerVolumetricFlow.KWH_BBL,
        internalUnit: units.PowerPerVolumetricFlow.W_M3S,
        enabled: true,
        defaultUnit: true,
        precision: 3,
        factor: 22643360.0,
      },
    },

    Pressure: {
      Pa: {
        key: units.Pressure.PA,
        internalUnit: null,
        enabled: false,
      },

      kPaa: {
        key: units.Pressure.KPAA,
        internalUnit: units.Pressure.PA,
        defaultUnit: true,
        enabled: true,
        factor: 1e3,
      },

      MPaa: {
        key: units.Pressure.MPAA,
        internalUnit: units.Pressure.PA,
        enabled: true,
        factor: 1e6,
      },
      Psia: {
        key: units.Pressure.PSIA,
        internalUnit: units.Pressure.PA,
        enabled: true,
        factor: 6.89476 * 1e3,
        defaultUnit: false,
      },
    },

    Speed: {
      'm/s': {
        key: units.Speed.M_S,
        internalUnit: null,
        enabled: false,
      },

      'km/h': {
        key: units.Speed.KM_H,
        internalUnit: units.Speed.M_S,
        enabled: true,
        defaultUnit: true,
        factor: function (value, toCapture) {
          if (toCapture) {
            return value * 3.6;
          }

          return value * (1000 / 3600);
        },
      },
    },

    Temperature: {
      K: {
        key: units.Temperature.KELVIN,
        internalUnit: null,
        enabled: true,
        precision: 3,
      },

      C: {
        key: units.Temperature.CELSIUS,
        internalUnit: units.Temperature.KELVIN,
        defaultUnit: true,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) {
            return value - 273.15;
          }
          return value + 273.15;
        },
        precision: 3,
      },

      F: {
        key: units.Temperature.FAHRENHEIT,
        internalUnit: units.Temperature.KELVIN,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) {
            return (9 / 5) * (value - 273 - 0.15) + 32;
          }

          return (5 / 9) * (value - 32) + 273.15;
        },
        precision: 3,
      },
    },

    U: {
      'W/m2K': {
        key: units.U.W_M2K,
        internalUnit: null,
        defaultUnit: true,
        enabled: true,
        precision: 5,
      },
      'kW/m2K': {
        key: units.U.KW_M2K,
        internalUnit: units.U.W_M2K,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 0.001;
          return value / 0.001;
        },
        precision: 5,
      },
      'BTU/hft2R': {
        key: units.U.BTU_HFT2R,
        internalUnit: units.U.W_M2K,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 0.176228039;
          return value / 0.176228039;
        },
        precision: 5,
      },
    },

    UA: {
      'W/K': {
        key: units.UA.W_K,
        internalUnit: null,
        defaultUnit: true,
        enabled: true,
      },
    },

    Volume: {
      m3: {
        key: units.Volume.M3,
        internalUnit: null,
        enabled: true,
      },

      bbl: {
        key: units.Volume.BBL,
        internalUnit: units.Volume.M3,
        defaultUnit: true,
        enabled: true,
        factor: 0.1589873,
      },

      BCF: {
        key: units.Volume.BCF,
        internalUnit: units.Volume.M3,
        enabled: false,
        factor: 28316846.592,
      },
    },

    VolumeFraction: {
      ppmv: {
        key: units.VolumeFraction.PPMV,
        internalUnit: units.VolumeFraction.FRACTION,
        enabled: true,
        precision: 1,
        factor: function (value, toCapture) {
          if (toCapture) return value * 1e6;
          return value / 1e6;
        },
      },

      'Vol%': {
        key: units.VolumeFraction.VOLUME_PERCENT,
        internalUnit: units.VolumeFraction.FRACTION,
        enabled: true,
        defaultUnit: true,
        precision: 5,
        factor: function (value, toCapture) {
          if (toCapture) return value * 1e2;
          return value / 1e2;
        },
      },

      fraction: {
        key: units.VolumeFraction.FRACTION,
        internalUnit: null,
        enabled: true,
        precision: 7,
      },
    },

    Volumetricflowrate: {
      'm3/s': {
        key: units.Volumetricflowrate.M3_S,
        internalUnit: null,
      },

      bpcd: {
        key: units.Volumetricflowrate.BPCD,
        internalUnit: units.Volumetricflowrate.M3_S,
        defaultUnit: false,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return (value * 86400.0) / 0.1589873;
          return (value / 86400.0) * 0.1589873;
        },
      },
      kbpcd: {
        key: units.Volumetricflowrate.KBPCD,
        internalUnit: units.Volumetricflowrate.M3_S,
        defaultUnit: true,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return (value * 0.001 * 86400.0) / 0.1589873;
          return ((value * 1000) / 86400.0) * 0.1589873;
        },
      },
      USGPM: {
        key: units.Volumetricflowrate.USGPM,
        internalUnit: units.Volumetricflowrate.M3_S,
        defaultUnit: true,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 15850.323141;
          return value / 15850.323141;
        },
      },
      'm3/d': {
        key: units.Volumetricflowrate.M3_D,
        internalUnit: units.Volumetricflowrate.M3_S,
        enabled: true,
        factor: function (value, toCapture) {
          if (toCapture) return value * 86400.0;
          return value / 86400;
        },
      },
    },

    VolumePerDistance: {
      'm3/m': {
        key: units.VolumePerDistance.M3_M,
        internalUnit: null,
        enabled: false,
      },

      'L/100 km': {
        key: units.VolumePerDistance.L_100KM,
        internalUnit: units.VolumePerDistance.M3_M,
        enabled: true,
        defaultUnit: true,
        factor: 1e-8,
      },
    },

    CurrencyPerStdH2Volume: {
      'CAD$/m3': {
        key: units.CurrencyPerStdH2Volume.CAD_M3,
        internalUnit: null,
        enabled: false,
      },

      'CAD$/MMSCF': {
        key: units.CurrencyPerStdH2Volume.CAD_MMSCF,
        internalUnit: units.CurrencyPerStdH2Volume.CAD_M3,
        enabled: true,
        defaultUnit: false,
        factor: 0.000035,
      },

      'CAD$/tonne': {
        key: units.CurrencyPerStdH2Volume.CAD_tonne,
        internalUnit: units.CurrencyPerStdH2Volume.CAD_M3,
        enabled: true,
        defaultUnit: true,
        factor: 8.37321e-5,
      },
    },

    MassPerStdH2Volume: {
      'kgCO2e/m3': {
        key: units.MassPerStdH2Volume.KGCO2E_M3,
        internalUnit: null,
        enabled: false,
      },

      'kgCO2e/MMSCF': {
        key: units.MassPerStdH2Volume.KGCO2E_MMSCF,
        internalUnit: units.MassPerStdH2Volume.KGCO2E_M3,
        enabled: true,
        defaultUnit: true,
        factor: 0.000035,
      },

      'kgCO2e/tonne': {
        key: units.MassPerStdH2Volume.KGCO2E_tonne,
        internalUnit: units.MassPerStdH2Volume.KGCO2E_M3,
        enabled: true,
        defaultUnit: false,
        factor: 0.000035 / 0.418,
      },
    },

    GasStdVolumePerVolume: {
      'm3/m3': {
        key: units.GasStdVolumePerVolume.M3_M3,
        internalUnit: null,
        enabled: false,
      },

      'scf/bbl': {
        key: units.GasStdVolumePerVolume.SCF_BBL,
        internalUnit: units.GasStdVolumePerVolume.M3_M3,
        enabled: true,
        defaultUnit: true,
        factor: function (value, toCapture) {
          if (toCapture) return (value * 0.1589873) / 0.028317;
          return (value * 0.028317) / 0.1589873;
        },
      },
    },

    AmineUseRate: {
      'USGPM/MMSCFD': {
        key: units.AmineUseRate.USGPM_MMSCFD,
        internalUnit: null,
        defaultUnit: true,
        enabled: true,
      },
    },
  };

  var unitSubsets = {
    MoleFraction: {
      MoleFractionNoPpm: [units.MoleFraction.FRACTION, units.MoleFraction.PERCENT],
    },
    VolumeFraction: {
      VolumeFractionNoPpm: [units.VolumeFraction.FRACTION, units.VolumeFraction.VOLUME_PERCENT],
    },
  };

  function parseFloatString(stringValue) {
    if (typeof stringValue === 'string') return parseFloat(stringValue.replace(/,/g, ''));
    return parseFloat(stringValue);
  }

  function formatNumber(nStr) {
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
  }

  function UnitConverter(quantity) {
    this.defaultPrecision = DEFAULT_PRECISION;

    this.setInputConversion = function (conversion) {
      this.inputConversion = conversion;
    };

    this.setOutputConversion = function (conversion) {
      this.outputConversion = conversion;
    };

    this.setPrecision = function (precision) {
      this.precision = precision;
    };

    this.convert = function (value, fromUnit, toUnit) {
      if (units[quantity]) {
        value = parseFloatString(value);

        if (isNaN(value)) return '';
        else if (!fromUnit || !toUnit) return value;

        this.setInputConversion(conversions[quantity][fromUnit]);
        this.setOutputConversion(conversions[quantity][toUnit]);

        var convertedValue;
        this.setPrecision(this.defaultPrecision);

        if (this.inputConversion && this.outputConversion) {
          if (this.inputConversion.key == this.outputConversion.key) {
            this.setPrecision(this.outputConversion.precision ? this.outputConversion.precision : this.precision);
            return value;
          }

          //conversion to internal unit
          if (this.inputConversion.internalUnit == toUnit) {
            if (typeof this.inputConversion.factor === 'function')
              convertedValue = this.inputConversion.factor(value, false);
            else convertedValue = value * this.inputConversion.factor;

            this.setPrecision(this.outputConversion.precision ? this.outputConversion.precision : this.precision);
          } else if (this.inputConversion.key == this.outputConversion.internalUnit) {
            if (typeof this.outputConversion.factor === 'function')
              convertedValue = this.outputConversion.factor(value, true);
            else convertedValue = value / this.outputConversion.factor;

            this.setPrecision(this.outputConversion.precision ? this.outputConversion.precision : this.precision);
          } else {
            //conversion between units
            if (typeof this.inputConversion.factor == 'function')
              convertedValue = this.inputConversion.factor(value, false);
            else convertedValue = value * this.inputConversion.factor;

            if (typeof this.outputConversion.factor == 'function')
              convertedValue = this.outputConversion.factor(convertedValue, true);
            else convertedValue /= this.outputConversion.factor;

            this.setPrecision(this.outputConversion.precision ? this.outputConversion.precision : this.precision);
          }

          return convertedValue;
        } else {
          throw 'Unsupported conversion qty:' + quantity + ' val:' + value + ' from:' + fromUnit + ' to:' + toUnit;
        }
      } else {
        throw 'Unsupported conversion qty:' + quantity + ' val:' + value + ' from:' + fromUnit + ' to:' + toUnit;
      }
    };
  }

  function UnitConverterCaller(quantity) {
    var convert = function (value, fromUnit, toUnit, formatConversion) {
      var converter = new UnitConverter(quantity);
      var convertedValue = converter.convert(value, fromUnit, toUnit, formatConversion);
      var conversionPrecision = converter.precision;

      if (formatConversion) throw 'U using old version';

      var conversionUtils = {
        getValue: function () {
          return convertedValue;
        },
        round: function () {
          // this is not cool... should return NaN...
          if (convertedValue === '') convertedValue = '';
          else convertedValue = parseFloat(convertedValue.toFixed(conversionPrecision));
          return this;
        },

        format: function () {
          return formatNumber(convertedValue);
        },
      };

      return conversionUtils;
    };

    var createUnitSelect = function (selectId, selectedValue, unitSubset, blackList) {
      var select = jQuery(selectId);
      var quantityUnits = conversions[quantity];
      if (!quantityUnits) throw 'Unknown quantity ' + quantity;

      var unitList = getUnitList(quantity, unitSubset, blackList);

      select.html('');

      for (var index in unitList) {
        select.append(
          jQuery('<option>', {
            value: unitList[index],
            text: unitList[index],
            selected: unitList[index] === selectedValue,
          })
        );
      }
    };

    function getUnitList(quantity, unitSubset, blackList) {
      var quantityConfiguration = conversions[quantity];
      var enabledUnitsForQuantity = Object.entries(conversions[quantity])
        .filter(entry => {
          return entry[1].enabled && (blackList ? blackList.indexOf(entry[1].key) >= 0 : true);
        })
        .map(entry => entry[1].key);

      var unitsToBeUsed = unitSubset ? unitSubsets[quantity][unitSubset] : enabledUnitsForQuantity;

      var unitList = [];
      for (var unit of unitsToBeUsed) {
        if (!quantityConfiguration[unit]) {
          throw `Unit ${unit} does not exist in ${quantity}`;
        }

        if (quantityConfiguration[unit].defaultUnit) unitList.unshift(unit);
        else unitList.push(unit);
      }

      return unitList;
    }

    var getInternalUnit = function () {
      var arr = conversions[quantity];
      //TODO: there must be another way to get the first property of an object
      for (var unit in arr) {
        return arr[unit].internalUnit === null ? arr[unit].key : arr[unit].internalUnit;
      }
    };

    var getDefaultUnit = function () {
      var arr = conversions[quantity];
      for (var unit in arr) {
        if (arr[unit].defaultUnit === true) return arr[unit].key;
      }
    };

    var unitInQuantity = function (unit) {
      return conversions[quantity][unit] !== undefined;
    };

    return {
      convert: convert,
      createUnitSelect: createUnitSelect,
      getInternalUnit: getInternalUnit,
      getDefaultUnit: getDefaultUnit,
      unitInQuantity: unitInQuantity,
    };
  }

  var uc = UnitConverterCaller;
  uc.units = units;
  uc.conversions = conversions;
  uc.parseFloatString = parseFloatString;
  uc.formatNumber = formatNumber;

  uc.DEFAULT_PRECISION = DEFAULT_PRECISION;

  if (ns == undefined) {
    win.unitConverter = uc;
  } else {
    ns.unitConverter = uc;
  }
})(window, window.unitConverter);
